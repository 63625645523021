<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" @submit.native.prevent>
      <Tabs type="card" name="modalLp" :animated="false">
        <TabPane tab="modalLp" name="basic" :label="this.$t('label.basic')" class="n-modal-container">
          <n-row-col2>
            <!-- companyId -->
            <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" label="company" :error="error.companyId" :disabled="isEdit || disabled" />
            <!-- language -->
            <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="isEdit || disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- type -->
            <n-field-enum v-model="model.type" label="type" enum-name="LandingPageType" :error="error.type" :disabled="isEdit || disabled" />
            <!-- variant -->
            <n-field-enum v-model="model.variant" field-name="variant" enum-name="LandingPageVariant" :error="error.variant" label="variant" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- layout -->
            <n-field-enum v-model="model.layout" label="layout" enum-name="LandingPageLayout" :error="error.layout" :disabled="disabled" />
            <!-- html -->
            <n-field-boolean v-model="model.html" label="html" :error="error.html" :disabled="disabled" />
          </n-row-col2>
          <n-row-col1>
            <!-- backgroundImage -->
            <n-field-text v-model="model.backgroundImage" field-name="backgroundImage" label="backgroundImage" :error="error.backgroundImage" :disabled="disabled" />
          </n-row-col1>
          <n-row-col2 v-if="model.html">
            <n-field-boolean v-model="model.wysiwyg" field-name="wysiwyg" label="wysiwyg" :error="error.wysiwyg" :disabled="disabled" />
            <n-field-boolean v-model="model.preview" field-name="preview" label="preview" :error="error.preview" :disabled="disabled" />
          </n-row-col2>
          <n-row-col1>
            <!-- content -->
            <n-text-editor v-model="model.content" :editor-height="150" :html="model.html && model.wysiwyg" field-name="content" label="content" :error="error.content" :disabled="disabled" />
          </n-row-col1>
          <n-row-col1 v-if="model.preview && model.html">
            <n-field-preview v-model="model.content" field-name="preview" label="preview" :error="error.content" :disabled="disabled" />
          </n-row-col1>
        </TabPane>
        <TabPane tab="modalLp" name="display" :label="this.$t('label.display')" class="n-modal-container">
          <n-row-col2>
            <!-- bottomEnabled -->
            <n-field-boolean v-model="model.bottomEnabled" label="bottomEnabled" :error="error.bottomEnabled" :disabled="disabled" />
            <!-- countdownEnabled -->
            <n-field-boolean v-model="model.countdownEnabled" label="countdownEnabled" :error="error.countdownEnabled" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- bottomBgColor -->
            <n-field-text v-model="model.bottomBgColor" field-name="bottomBgColor" label="bottomBgColor" :error="error.bottomBgColor" :disabled="disabled" />
            <!-- countdownBgColor -->
            <n-field-text v-model="model.countdownBgColor" field-name="countdownBgColor" label="countdownBgColor" :error="error.countdownBgColor" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- bottomButtonText -->
            <n-field-text v-model="model.bottomButtonText" field-name="bottomButtonText" label="bottomButtonText" :error="error.bottomButtonText" :disabled="disabled" />
            <!-- countdownDuration -->
            <n-field-id v-model="model.countdownDuration" field-name="countdownDuration" label="countdownDuration" :error="error.countdownDuration" :disabled="disabled" />
          </n-row-col2>
          <n-row-col2>
            <!-- discountEnabled -->
            <n-field-boolean v-model="model.discountEnabled" label="discountEnabled" :error="error.discountEnabled" :disabled="disabled" />
            <!-- discountPercent -->
            <n-field-percent v-model="model.discountPercent" format="0 %" field-name="discountPercent" :error="error.discountPercent" label="discountPercent" :disabled="disabled" />
          </n-row-col2>
          <n-row-col1>
            <!-- discountBgImage -->
            <n-field-text v-model="model.discountBgImage" field-name="discountBgImage" label="discountBgImage" :error="error.discountBgImage" :disabled="disabled" />
          </n-row-col1>
        </TabPane>
      </Tabs>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, add, edit } from '@/api/company/company-lp';

export default NModal.extend({
  name: 'ModalCompanyTranslation',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
