<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th rowspan="2">{{ $t('label.id') }}</th>
            <th rowspan="2">{{ $t('label.type') }}</th>
            <th rowspan="2">{{ $t('label.layout') }}</th>
            <th rowspan="2">{{ $t('label.variant') }}</th>
            <th rowspan="2">{{ $t('label.language') }}</th>
            <th rowspan="2">{{ $t('label.html') }}</th>
            <th colspan="3">{{ $t('label.display') }}</th>
            <th v-table-action rowspan="2" class="action-edit">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th>{{ $t('label.bottom') }}</th>
            <th>{{ $t('label.discount') }}</th>
            <th>{{ $t('label.countdown') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-enums:LandingPageType="row.type"></td>
            <td v-enums:LandingPageLayout="row.layout"></td>
            <td v-enums:LandingPageVariant="row.variant"></td>
            <td v-enums:Language="row.language"></td>
            <td><n-icon-boolean :value="row.html" /></td>
            <td><n-icon-boolean :value="row.bottomEnabled" /></td>
            <td><n-icon-boolean :value="row.discountEnabled" /></td>
            <td><n-icon-boolean :value="row.countdownEnabled" /></td>
            <td class="n-button-box">
              <nb-custom v-rw type="error" custom-icon="fas fa-clone" text="copy" @on-click="openCopy(row)" />
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import ModalCompanyLandingPage from './ModalCompanyLandingPage';
import { fetch } from '@/api/company/company-lp';
import {beanCopy} from "@/helpers/utils";

export default BaseCompanyView.extend({
  name: 'CompanyLandingPage',

  data() {
    return {
      preload: true,
      condition: {
        companyId: 0,
      },
    };
  },

  methods: {
    doLoad() {
      let companyId = this.condition.companyId;
      return fetch({ companyId });
    },

    parse(data) {
      this.records = data;
    },

    openModal(model = {}) {
      this.createModal(ModalCompanyLandingPage, { model, on: this });
    },

    openCopy(o) {
      let clone = beanCopy(o);
      clone.id = undefined;
      clone.version = undefined;
      this.createModal(ModalCompanyLandingPage, { model: clone, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  th.rw-action {
    width: 8.5rem;
  }
}
</style>
